import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './main.css';

function App() {
  return (
    <>
      <header className="d-flex justify-content-center align-items-center">
        <h1>Adriatic Subsea Technologies</h1>
      </header>

      <div className="container mt-5 mb-5">
        <div className="row justify-content-space-around align-items-center">
          <div className="col-md-6">
            <p className="paragraph"><span>Precision Engineering for Maritime Innovation:</span><br />
              Explore the depths of precision engineering with us, where our commitment to innovation and maintenance sets new standards for underwater instruments, promoting sustainability and efficiency in marine aquaculture and fish processing operations.
            </p>
          </div>
          <div className="col-md-4">
            <img src="/img/intro.jpg" alt="Intro" className="img-fluid" />
          </div>
        </div>
      </div>

      <div className="container container-styled services-list mb-5">
        <h2 className="mb-5">Our Services:</h2>
        <ol>
          <li>
            <div className="content">
              <h3>Instrument Design and Maintenance</h3>
              <p>Adriatic Subsea Technologies excels in innovative and precise design of underwater instruments, covering complex machinery for fish processing and marine aquaculture infrastructure. From advanced underwater cameras to complex fish processing machinery, our technical expertise ensures optimal functionality and resilience in challenging marine conditions. Our commitment to innovation and rigorous maintenance ensures sustained efficiency, contributing to seamless marine aquaculture and fish processing operations.</p>
            </div>
          </li>
          <li>
            <div className="content">
              <h3>Underwater Measurements and Imaging</h3>
              <p>Our underwater measurement services prioritize precision and reliability. Utilizing advanced video recording capabilities, precise sensors, cameras, and remotely operated vehicles (ROVs), we offer comprehensive data collection with a focus on professionalism and accuracy.</p>
            </div>
          </li>
          <li>
            <div className="content">
              <h3>Electronics and Optics Beneath the Surface</h3>
              <p>Explore the electronic and optic dimensions beneath the sea through our specialized services. We employ cutting-edge technology solutions tailored for underwater conditions, ensuring dependable performance and detailed data collection in a professional context.</p>
            </div>
          </li>
        </ol>
      </div>

      <div className="container mb-5">
        <h2 className="mb-5">Why Choose Adriatic Subsea Technologies?</h2>
        <div className="row">
          <div className="col">
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src="/img/carousel1.jpg" className="d-block w-100" alt="Innovation" />
                  <div className="carousel-caption d-none d-md-block">
                    <h5>Innovation:</h5>
                    <p>We constantly push the boundaries of technology, delivering innovative solutions for underwater exploration and measurement.</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img src="/img/carousel2.jpg" className="d-block w-100" alt="Expertise" />
                  <div className="carousel-caption d-none d-md-block">
                    <h5>Expertise:</h5>
                    <p>Our team of skilled professionals provides high-quality services customized to meet your unique needs.</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img src="/img/carousel3.jpg" className="d-block w-100" alt="Reliability" />
                  <div className="carousel-caption d-none d-md-block">
                    <h5>Reliability:</h5>
                    <p>Rely on our commitment to delivering solutions that are not only reliable but also durable, designed to withstand the challenges of the marine environment.</p>
                  </div>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <ul className="d-md-none mb-5">
        <li>
          <h5>Innovation:</h5>
          <p>We constantly push the boundaries of technology, delivering innovative solutions for underwater exploration and measurement.</p>
        </li>
        <li>
          <h5>Expertise:</h5>
          <p>Our team of skilled professionals provides high-quality services customized to meet your unique needs.</p>
        </li>
        <li>
          <h5>Reliability:</h5>
          <p>Rely on our commitment to delivering solutions that are not only reliable but also durable, designed to withstand the challenges of the marine environment.</p>
        </li>
      </ul>

      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-5 mt-5 mb-5">
            <img src="/img/img4.png" className="img-fluid" alt="Images" />
          </div>
          <div className="col-12 col-md-6">
            <div className="custom-list mt-5">
              <h4 className="mb-3">One of our underwater camera solutions is permanently installed in front of Institut Ruđer Bošković in Martinska bay:</h4>
              <ul>
                <li><span><a target='_blank' href="https://www.youtube.com/live/eTSIImrw9NE">Youtube</a></span></li>
                <li><span><a target='_blank' href="https://www.instagram.com/p/C83932CvrJk/">Glavata želva (Caretta Caretta) 25.06.2024</a></span></li>
                <li><span><a target='_blank' href="https://www.instagram.com/p/C7OvZt_vS0g/">Cipli (Mugilidae sp.) 16.05.2024</a></span></li>
                <li><span><a target='_blank' href="https://www.instagram.com/p/C7B6INjPnfc/">Fratar (Diplodus vulgaris) 15.05.2024</a></span></li>
                <li><span><a target='_blank' href="https://www.instagram.com/p/C6tNXYZvtvG/">Salpa (Sarpa salpa) 07.05.2024</a></span></li>
                <li><span><a target='_blank' href="https://www.instagram.com/p/C7eN_9OqjnT/">Šarag (Diplodus sargus) 24.05.2024</a></span></li>
                <li><span><a target='_blank' href="https://www.instagram.com/p/CLWwj3_AG64/">Sipa (Sepia officinalis) 04.07.2020</a></span></li>
                <li><span><a target='_blank' href="https://www.instagram.com/p/CKoF82cghjh/">Orada (Sparus aurata) 22.10.2020.</a></span></li>
                <li><span><a target='_blank' href="https://www.instagram.com/p/CKl1FoglR3j/">Gof (Seriola dumerili) 19.09.2020.</a></span></li>
                <li><span><a target='_blank' href="https://www.instagram.com/p/CKWV832A2KB/">Lumbrak (Symphodus tinca) 07.01.2021</a></span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <footer className="d-flex justify-content-center align-items-center">
        <div className="container mt-5">
          <h5>For inquiries and collaboration opportunities, reach out to Adriatic Subsea Technologies using the
            following contact details:</h5>
          <ul>
            <li><i className="fa-solid fa-signature fa-lg icon-color"></i>Adriatic Subsea Technologies d.o.o.</li>
            <li><i className="fa-solid fa-envelope fa-lg icon-color"></i>adriatic.subsea.tech@gmail.com</li>
            <li><i className="fa-solid fa-location-dot fa-lg icon-color"></i>Cesta dr. Franje Tuđmana
              81, 21212 Kaštel Sućurac, Croatia</li>
            <li><i className="fa-solid fa-fingerprint fa-lg icon-color"></i>OIB: 84519731285</li>
          </ul>
          <p>Dive Deeper with Adriatic Subsea Technologies - Precision Engineering for Maritime Innovation.</p>
          <p className="center-text mt-5">Adriatic Subsea Technologies © 2024</p>
        </div>
      </footer>

    </>
  );
}

export default App;
